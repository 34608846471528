import React, { useState } from "react";
import { Linkedin, Youtube, Instagram } from "react-feather";
import logo from "../Assets/BrandLogo/whitelogo.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";

function Footer() {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const subEmail = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.trim()) {
      toast.error("Email is required");
      return;
    }

    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    setLoader(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/management/subscribe-email/`,
        { email }
      );
      setLoader(false);
      toast.success("Subscribed successfully!");
      setEmail("");
    } catch (error) {
      setLoader(false);
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    // <footer className="bg-blue-900 text-white py-12">
    //   <div className="container mx-auto px-4 xs:px-6 sml:px-8 lg:px-24">
    //     {/* Top Section */}
    //     <div className="flex flex-col md:flex-row md:items-center justify-between mb-8">
    //       <div className="mb-6 md:mb-0">
    //         <a href="/">
    //           <img src={logo} alt="Logo" className="w-40 mb-3" />
    //         </a>
    //       </div>

    //       {/* Social Media Icons */}
    //       <div className="flex space-x-4">
    //         <a
    //           href="https://www.linkedin.com/company/labopsmeditechnology/"
    //           target="_blank"
    //           rel="noopener noreferrer"
    //           className="p-2 bg-white rounded-full text-blue-900 hover:bg-blue-500 hover:text-white shadow-md transition-transform duration-300 transform hover:scale-110"
    //           aria-label="LinkedIn"
    //         >
    //           <Linkedin className="w-6 h-6" />
    //         </a>
    //         <a
    //           href="https://www.youtube.com/@Labops2023"
    //           target="_blank"
    //           rel="noopener noreferrer"
    //           className="p-2 bg-white rounded-full text-blue-900 hover:bg-blue-500 hover:text-white shadow-md transition-transform duration-300 transform hover:scale-110"
    //           aria-label="YouTube"
    //         >
    //           <Youtube className="w-6 h-6" />
    //         </a>
    //         <a
    //           href="https://www.instagram.com/labops.in?igsh=MXJpdzJpNnQ5azZuag=="
    //           target="_blank"
    //           rel="noopener noreferrer"
    //           className="p-2 bg-white rounded-full text-blue-900 hover:bg-blue-500 hover:text-white shadow-md transition-transform duration-300 transform hover:scale-110"
    //           aria-label="Instagram"
    //         >
    //           <Instagram className="w-6 h-6" />
    //         </a>
    //       </div>
    //     </div>

    //     {/* Links Section */}
    //     <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 text-left">
    //       {/* Product Links */}
    //       <div>
    //         <h3 className="text-lg font-semibold mb-4">Product</h3>
    //         <ul className="space-y-2">
    //           <li>
    //             <a href="/" className="text-gray-300 hover:text-white">
    //               Home
    //             </a>
    //           </li>
    //           <li>
    //             <a href="/about" className="text-gray-300 hover:text-white">
    //               About Us
    //             </a>
    //           </li>
    //           <li>
    //             <a href="/blogs" className="text-gray-300 hover:text-white">
    //               Blog
    //             </a>
    //           </li>
    //           <li>
    //             <a href="/contact" className="text-gray-300 hover:text-white">
    //               Contact Us
    //             </a>
    //           </li>
    //         </ul>
    //       </div>

    //       {/* Legal Links */}
    //       <div>
    //         <h3 className="text-lg font-semibold mb-4">Legal</h3>
    //         <ul className="space-y-2">
    //           <li>
    //             <a
    //               href="/privacy-policy"
    //               className="text-gray-300 hover:text-white"
    //             >
    //               Privacy Policy
    //             </a>
    //           </li>
    //           <li>
    //             <a
    //               href="/terms-conditions"
    //               className="text-gray-300 hover:text-white"
    //             >
    //               Terms of Service
    //             </a>
    //           </li>
    //         </ul>
    //       </div>

    //       {/* Contact Info */}
    //       <div>
    //         <h3 className="text-lg font-semibold mb-4">Contact</h3>
    //         <p className="text-gray-300">Lake Town, Kolkata, India, 700089</p>
    //         <p className="text-gray-300 mt-2">+91 9002841677</p>
    //         <p className="text-gray-300 mt-2">contact@labops.in</p>
    //       </div>

    //       {/* Newsletter Subscription */}
    //       <div>
    //         <h3 className="text-lg font-semibold mb-4">Stay Updated</h3>
    //         <p className="text-gray-300 mb-4">
    //           Sign up for our newsletter for updates and special offers.
    //         </p>
    //         <div className="flex flex-col space-y-2">
    //           <input
    //             type="email"
    //             value={email}
    //             onChange={(e) => setEmail(e.target.value)}
    //             placeholder="Enter your email"
    //             className="px-4 py-2 rounded bg-blue-800 text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
    //           />
    //           <button
    //             onClick={subEmail}
    //             type="submit"
    //             className="flex items-center justify-center h-10 bg-blue-600 hover:bg-blue-500 text-white py-2 rounded transition duration-300"
    //           >
    //             {loader ? (
    //               <ThreeDots
    //                 visible
    //                 height="25"
    //                 width="25"
    //                 color="white"
    //                 ariaLabel="loading"
    //               />
    //             ) : (
    //               "Subscribe"
    //             )}
    //           </button>
    //         </div>
    //       </div>
    //     </div>

    //     {/* Footer bottom */}
    //     <div className="mt-8 border-t border-gray-700 pt-4 text-center">
    //       <p className="text-gray-400 text-sm">
    //         © 2024 Labops. All Rights Reserved. Designed by Labops.
    //       </p>
    //     </div>
    //   </div>
    //   <ToastContainer />
    // </footer>
    <footer className="bg-gradient-to-r from-[#2C2CCB] to-[#6586F1] text-white py-10 px-6 md:px-16 lg:px-32 ">
      <ToastContainer />
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Legal Section */}
        <div>
          <h3 className="font-semibold text-lg">Legal</h3>
          <ul className="mt-2 space-y-1">
            <li>
              <a href="/privacy-policy" className="hover:underline">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/terms-conditions" className="hover:underline">
                Terms of Service
              </a>
            </li>
          </ul>
        </div>

        {/* Contact Section */}
        <div>
          <h3 className="font-semibold text-lg">Contact</h3>
          <p className="mt-2">Lake Town, Kolkata, India, 700089</p>
          <p>+91 9002841677</p>
          <p>contact@labops.in</p>
        </div>

        {/* Stay Updated Section */}
        <div>
          <h3 className="font-semibold text-lg">Stay Updated</h3>
          <p className="mt-2">
            Sign up for our newsletter to receive the latest updates and special
            offers.
          </p>
          <div className="mt-3">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your Email Address"
              className="w-full px-4 py-2 rounded-md bg-white/20 placeholder-white border border-white focus:outline-none focus:ring-2 focus:ring-white"
            />
            <button
              onClick={subEmail}
              className="w-full mt-2 flex justify-center items-center bg-[#1F4194] hover:bg-[#19377A] text-white h-[3rem] rounded-md"
            >
              {loader ? (
                <ThreeDots
                  visible
                  height="50"
                  width="50"
                  color="white"
                  ariaLabel="loading"
                />
              ) : (
                "Subscribe"
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="text-center mt-10 text-white text-sm">
        &copy; {new Date().getFullYear()} Labops. All Rights Reserved. Designed
        by Labops.
      </div>
    </footer>
  );
}

export default Footer;
