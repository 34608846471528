import logo from "./logo.svg";

import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./Pages/Home";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Security from "./Pages/Security";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsCondions from "./Pages/TermsCondions";
import Blogs from "./Pages/Blogs";
import BlogDetail from "./Pages/BlogDetail";
import { ToastContainer } from "react-toastify";
import PricingPlans from "./Pages/PricingPlans";
import Mapper from "./Components/Mapper";

function App() {
  return (
    <>
      <Router>
        <ToastContainer />
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/security" element={<Security />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsCondions />} />
          <Route path="/pricing-plans" element={<PricingPlans />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog-detail/:slug" element={<BlogDetail />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
