import React, { useEffect, useRef, useState } from "react";
import mobileImg1 from "../Assets/Home/002 1.png";
import img1 from "../Assets/Home/Group 107.png";
import img2 from "../Assets/Home/Group 393.png";
import img3 from "../Assets/Home/mob.png";
import img4 from "../Assets/Home/BG.png";
import img5 from "../Assets/Home/Frame 1000006567.png";
import img6 from "../Assets/Home/image.png";
import img7 from "../Assets/Home/Rectangle 158.png";
import img8 from "../Assets/Home/Rectangle 160.png";
import img9 from "../Assets/Home/009 1.png";
import video from "../Assets/Home/video.mp4";
import video1 from "../Assets/Home/hero1.mp4";
import img10 from "../Assets/Home/image1.png";
import img11 from "../Assets/Home/Banner.png";
import img12 from "../Assets/Home/Banner Background.png";
import img13 from "../Assets/Home/img2.png";
import logo from "../Assets/logo.png";
import marl from "../Assets/Home/marl.png";
import aws from "../Assets/Home/aws.png";
import dipit from "../Assets/Home/dipit.png";
import stanford from "../Assets/Home/stanford.png";
import map from "../Assets/Home/map.webp";
import doctorHalfImg from "../Assets/Home/Dr image.webp";
import comingbg from "../Assets/Home/coming soon background .png";
import appplaystore from "../Assets/Home/app store play store.png";
import Slider from "react-slick";
import {
  Activity,
  ArrowRight,
  Award,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Cpu,
  ExternalLink,
  Lock,
  Phone,
  PhoneCall,
} from "react-feather";
import { useNavigate } from "react-router-dom";
import ShinyButton from "./magicui/ShinyButton";
import {
  AnimatedGradientText,
  AnimatedNormalGradientText,
} from "./magicui/AnimatedGradientText";
import { cn } from "../lib/utils";
import axios from "axios";
import { AnimatePresence, motion, useInView } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InfiniteMarquee from "./InfiniteMarquee";
function HomeComp() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [expanded, setExpanded] = useState({});
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const sectionRef = useRef(null);
  const sectionRef1 = useRef(null);
  const sectionRef2 = useRef(null);
  const isInView = useInView(sectionRef, { once: true, margin: "-100px" });
  const isInView1 = useInView(sectionRef1, { once: true, margin: "-100px" });
  const isInView2 = useInView(sectionRef2, { once: true, margin: "-100px" });
  const testimonials = [
    {
      name: "Janette Sexton",
      position: "Accounting Manager",
      company: "Tribal.com",
      companyLogo:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Tribal_Logo.svg/512px-Tribal_Logo.svg.png",
      image: "https://randomuser.me/api/portraits/women/44.jpg",
      message:
        "Seal is our go-to for seamless transactions. The speed and flexibility it offers have completely transformed the way we handle payments daily.",
      date: "Jun 24, 2024",
    },
    {
      name: "Rosa Griffin",
      position: "Accounting Manager",
      company: "Amplitude.com",
      companyLogo:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Amplitude_Logo.svg/512px-Amplitude_Logo.svg.png",
      image: "https://randomuser.me/api/portraits/women/68.jpg",
      message:
        "Seal’s efficiency is unmatched. Their team truly understands financial workflows and provides solutions that make a real impact on our business.",
      date: "Jun 24, 2024",
    },
    {
      name: "Michael Carter",
      position: "CTO",
      company: "TechNova",
      companyLogo: "https://via.placeholder.com/80",
      image: "https://randomuser.me/api/portraits/men/55.jpg",
      message:
        "Seal has been a game-changer for us. Their reliability and seamless transaction process have made managing financial operations easier than ever.",
      date: "May 18, 2024",
    },
    {
      name: "Sophia Lee",
      position: "Finance Head",
      company: "FinCorp",
      companyLogo: "https://via.placeholder.com/80",
      image: "https://randomuser.me/api/portraits/women/72.jpg",
      message:
        "Customer support is outstanding. Seal’s commitment to ensuring a smooth experience is evident in every interaction we’ve had with their platform.",
      date: "Apr 10, 2024",
    },
    {
      name: "David Wilson",
      position: "Head of Payments",
      company: "PayFast",
      companyLogo: "https://via.placeholder.com/80",
      image: "https://randomuser.me/api/portraits/men/42.jpg",
      message:
        "Seal’s platform is intuitive, fast, and reliable. Their dedication to making payments hassle-free has been a key factor in our business’s success.",
      date: "Mar 5, 2024",
    },
  ];
  const sliderRef = React.useRef(null);

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
  };
  const toggleReadMore = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const getAllBlogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/blogs/`
      );
      console.log(response?.data);
      setBlogs(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Options for displaying date as "30 Jan 2024"
    const options = { day: "numeric", month: "short", year: "numeric" };

    // Format the date using toLocaleDateString with English locale
    return date.toLocaleDateString("en-GB", options);
  };

  useEffect(() => {
    getAllBlogs();
  }, []);
  const accordionData = [
    {
      question: "Is Labops suitable for both pathology and diagnostic labs?",
      answer:
        "Yes, Labops is designed to cater to the needs of both pathology and diagnostic labs, making it a versatile solution for various laboratory settings.",
    },
    {
      question: "How secure is Labops for health data?",
      answer:
        "Labops prioritizes your privacy and security. With end-to-end encryption, HIPAA compliance, and regular audits, your health data is safeguarded with the highest standards.",
    },
    {
      question: "Can I access Labops from mobile devices?",
      answer:
        "Yes, Labops is accessible from both desktop and mobile devices, ensuring you can manage your lab anytime, anywhere.",
    },
    {
      question: "Does Labops support multi-location labs?",
      answer:
        "Absolutely! Labops is equipped to handle multi-location labs efficiently, allowing you to manage all your branches from a single dashboard.",
    },
    {
      question: "Does Labops support multi-location labs?",
      answer:
        "Absolutely! Labops is equipped to handle multi-location labs efficiently, allowing you to manage all your branches from a single dashboard.",
    },
  ];
  return (
    <>
      <div
        ref={sectionRef1}
        className="relative bg-gradient-to-l px-4 md:px-0   from-white to-blue-50 w-full flex justify-center pt-[8rem] md:pt-0 h-[28rem] md:h-[92.5vh] bg-cover bg-center"
      >
        {/* Flex container for two components */}
        <div className="flex md:pl-[10%] justify-between w-full h-full relative">
          {/* Left Side Content */}
          <motion.div
            className="flex flex-col p-4 relative z-20 justify-center max-w-[55rem]"
            initial={{ opacity: 0, x: -30 }}
            animate={isInView1 ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <motion.div
              className="left-0 md:inline animate-gradient hidden  relative my-6 md:mb-6 md:mt-0"
              initial={{ opacity: 0, y: -20 }}
              animate={isInView1 ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <AnimatedGradientText>
                <div
                  className={cn(
                    `  w-[12rem] text-center bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent`
                  )}
                >
                  Introducing Labops
                </div>
              </AnimatedGradientText>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={isInView1 ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
            >
              <AnimatedNormalGradientText>
                <span
                  className={cn(
                    `inline animate-gradient font-[600] text-[2.2rem] md:leading-[4.8rem] uppercase md:text-[4rem] bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent`
                  )}
                >
                  Your AI-Powered Health <br /> Companion
                </span>
              </AnimatedNormalGradientText>
            </motion.div>

            <motion.span
              className="lg:text-[1.3rem] text-gray-800 text-[1rem] block mt-[1rem]"
              initial={{ opacity: 0, y: 20 }}
              animate={isInView1 ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.7, ease: "easeOut", delay: 0.6 }}
            >
              Labops revolutionizes diagnostic healthcare by seamlessly
              connecting labs <br /> and patients, leveraging technology to
              drive efficiency, affordability.
            </motion.span>
          </motion.div>

          {/* Right Image */}
          <motion.div
            className="absolute  hidden lg:block right-[0rem] top-[0rem]"
            initial={{ opacity: 0, x: 50 }}
            animate={isInView1 ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1, ease: "easeOut", delay: 0.5 }}
          >
            <img
              src={img12}
              className="h-[58rem] w-[70rem]"
              alt="Image description"
            />
          </motion.div>
        </div>
      </div>
      <div
        ref={sectionRef}
        className="w-full bg-gradient-to-l from-white to-blue-50 py-12 px-6 md:px-16 lg:px-24 text-center relative overflow-hidden"
      >
        {/* Mentorship Logos */}
        <motion.div
          className="max-w-5xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <h2 className="text-lg md:text-xl opacity-80 font-semibold text-gray-700 my-[3rem]">
            MENTORSHIP AND SUPPORT BY
          </h2>

          <motion.div
            className="flex flex-wrap justify-center items-center gap-5 md:gap-[5rem]"
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={{
              hidden: { opacity: 0, y: 30 },
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  staggerChildren: 0.2,
                  duration: 0.6,
                  ease: "easeOut",
                },
              },
            }}
          >
            <motion.img
              src={marl}
              alt="Marl Logo"
              className="md:w-[10rem] w-[8rem] object-contain"
              variants={{
                hidden: { opacity: 0, scale: 0.8 },
                visible: { opacity: 1, scale: 1 },
              }}
            />
            <motion.img
              src={aws}
              alt="AWS Logo"
              className="w-[5rem] md:w-[7.5rem] object-contain"
              variants={{
                hidden: { opacity: 0, scale: 0.8 },
                visible: { opacity: 1, scale: 1 },
              }}
            />
            <motion.img
              src={dipit}
              alt="Dipit Logo"
              className="w-[9rem] md:w-[12rem] object-contain"
              variants={{
                hidden: { opacity: 0, scale: 0.8 },
                visible: { opacity: 1, scale: 1 },
              }}
            />
            <motion.img
              src={stanford}
              alt="Stanford Logo"
              className="w-[6rem]  md:w-[9rem] object-contain"
              variants={{
                hidden: { opacity: 0, scale: 0.8 },
                visible: { opacity: 1, scale: 1 },
              }}
            />
          </motion.div>
        </motion.div>

        {/* Hero Section */}
        <motion.div
          className="relative mt-16 md:mt-24 flex justify-center items-center"
          initial={{ opacity: 0, y: 40 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <motion.img
            src={map}
            alt="World Map"
            className="w-full"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={isInView ? { scale: 1, opacity: 1 } : {}}
            transition={{ duration: 1, ease: "easeOut" }}
          />
          <motion.div
            className="absolute inset-0 flex justify-center items-center"
            initial={{ opacity: 0, y: -30 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.2, ease: "easeOut", delay: 0.2 }}
          >
            <AnimatedNormalGradientText>
              <span
                className={cn(
                  `inline animate-gradient font-[600] opacity-80 text-[2.2rem] md:leading-[4.8rem] uppercase md:text-[4rem] bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent`
                )}
              >
                AI-Powered Healthcare
              </span>
            </AnimatedNormalGradientText>
          </motion.div>
        </motion.div>
      </div>
      {/* <div className="w-full relative z-30 flex flex-col text-[#3B3B3B] items-center bg-white p-4 md:p-8 lg:p-8">
        <div className="w-full  max-w-[81rem] grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2">
          <div className="bg-[#F6EBD3] flex flex-col items-start p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="w-16 h-16 bg-white rounded-full flex justify-center items-center mb-4">
              <Award size={30} />
            </div>
            <span className="md:text-[1.5rem] text-[1.4rem] font-semibold mb-2">
              Reliable
            </span>
            <p className="text-base text-start">
              Ensuring consistent performance, Labops enhances operational
              reliability for labs, enabling seamless coordination with patients
              while maintaining precision and trust throughout the diagnostic
              journey.
            </p>
          </div>

          <div className="bg-[#E1E3D4] flex flex-col items-start p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="w-16 h-16 bg-white rounded-full flex justify-center items-center mb-4">
              <Activity size={30} />
            </div>
            <span className="md:text-[1.5rem] text-[1.4rem] font-semibold mb-2">
              Fast
            </span>
            <p className="text-base text-start">blogs
              Accelerating healthcare interactions, Labops speeds up lab
              processes and patient bookings, driving efficiency without
              compromising quality, so labs and patients can access care faster
              than ever
            </p>
          </div>

          <div className="bg-[#B8DDEC] flex flex-col items-start p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="w-16 h-16 bg-white rounded-full flex justify-center items-center mb-4">
              <Lock size={30} />
            </div>
            <span className="md:text-[1.5rem] text-[1.4rem] font-semibold mb-2">
              Secure
            </span>
            <p className="text-base text-start">
              Labops is a comprehensive software solution designed to streamline
              Built with top-tier data protection, Labops safeguards sensitive
              health information, providing labs and patients peace of mind with
              uncompromised security at every step of the process
            </p>
          </div>

          <div className="bg-[#D8C4D4] flex flex-col items-start p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
            <div className="w-16 h-16 bg-white rounded-full flex justify-center items-center mb-4">
              <Cpu size={30} />
            </div>
            <span className="md:text-[1.5rem] text-[1.4rem] font-semibold mb-2">
              Advanced
            </span>
            <p className="text-base text-start text-[#6B6B6B]">
              Powered by AI and modern technology, Labops delivers advanced
              solutions that elevate lab management and patient care, setting a
              new standard for innovation in healthcare services.
            </p>
          </div>
        </div>
      </div> */}
      {/* <div className="w-full flex flex-col items-center bg-white py-5 px-4 md:px-8 lg:px-8 text-[#3B3B3B]">
        <div className="w-full max-w-[81rem] flex flex-col gap-5">
        
          <span className="md:text-4xl text-3xl font-medium">
            Powerful Features
          </span>
          <div className="w-full flex flex-col xl:flex-row gap-5">
            <div className="flex flex-col md:flex-row  xl:flex-col gap-5">
              <div className="bg-[#F7F8F5] flex-1 xl:w-full xl:max-w-xs rounded-lg p-6 flex flex-col gap-y-3">
                <span className="text-xl font-medium">Test Booking</span>
                <span className="text-[#4E4E4E]">
                  Book diagnostic tests effortlessly with the Labops app,
                  offering quick access to labs, easy scheduling, and seamless
                  integration for a smoother healthcare experience.
                </span>
              </div>
              <div className="bg-[#F7F8F5] flex-1 xl:w-full xl:max-w-xs rounded-lg p-6 flex flex-col gap-y-3">
                <span className="text-xl font-medium">
                  AI Health Monitoring
                </span>
                <span className="text-[#4E4E4E]">
                  Monitor your health with AI-powered insights, helping you
                  track vital trends and receive personalized health guidance
                  for proactive care, all within the Labops app.
                </span>
              </div>
              <div className="bg-[#F7F8F5] flex-1 xl:w-full xl:max-w-xs rounded-lg p-6 flex flex-col gap-y-3">
                <span className="text-xl font-medium">History Management</span>
                <span className="text-[#4E4E4E]">
                  Keep all your medical reports and health records organized in
                  one place, with easy access to your test history for better
                  health tracking and management
                </span>
              </div>
            </div>
            <div className="lg:flex-1 h-[30rem] lg:h-auto flex justify-center items-end bg-[#B9E97D] rounded-lg ">
              <img
                className="w-full max-w-[18rem]  md:max-w-[20rem] h-auto"
                src={img3}
                alt="Feature illustration"
              />
            </div>
            <div className="flex flex-col md:flex-row  xl:flex-col gap-5">
              <div className="bg-[#F7F8F5] flex-1 xl:w-full xl:max-w-xs rounded-lg p-6 flex flex-col gap-y-3">
                <span className="text-xl font-medium">More Affordable</span>
                <span className="text-[#4E4E4E]">
                  Enjoy cost-effective healthcare with Labops, offering
                  discounted test rates and affordable diagnostic solutions to
                  make quality medical services accessible to everyone.
                </span>
              </div>
              <div className="bg-[#F7F8F5] flex-1 xl:w-full xl:max-w-xs rounded-lg p-6 flex flex-col gap-y-3">
                <span className="text-xl font-medium">Best Service</span>
                <span className="text-[#4E4E4E]">
                  Experience top-notch healthcare services with Labops,
                  combining convenience, efficiency, and personalized care to
                  ensure the best diagnostic experience for every patient.
                </span>
              </div>
              <div className="bg-[#F7F8F5] flex-1 xl:w-full xl:max-w-xs rounded-lg p-6 flex flex-col gap-y-3">
                <span className="text-xl font-medium">Safe</span>
                <span className="text-[#4E4E4E]">
                  Labops guarantees the highest level of data security, keeping
                  your health information safe and secure while ensuring privacy
                  in all your healthcare interactions.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* 
      <div className="w-full flex flex-col items-center bg-[#F2F4EF] py-16 px-4 md:px-8 lg:px-12 text-[#3B3B3B]">
        <div className="w-full max-w-[81rem] flex flex-col gap-8">
        
          <span className="md:text-4xl text-3xl font-semibold text-center md:text-left">
            Need to run your diagnostic
          </span>

     
          <p className="max-w-[50rem] text-lg leading-relaxed text-center md:text-left">
            Yes, Labops is designed to cater to the needs of both pathology and
            diagnostic labs, making it a versatile solution for various
            laboratory settings.
          </p>

       
          <div className="w-full flex gap-y-2 relative flex-col md:flex-row justify-center items-center bg-[#F2F4EF] gap-0">
          
            <div className="w-[90%]  md:w-[72rem] rounded-lg relative flex justify-center items-center">
              <video
                autoPlay
                loop
                muted
                playsInline
                className="w-full rounded-md"
              >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
           
            </div>

          
            <div className="w-full md:w-1/2 flex justify-center items-center">
              <img className="w-full max-w-[35rem]" src={img10} alt="Labops" />
            </div>
          </div>
        </div>
      </div> */}
      {/* <div
        ref={sectionRef2}
        className="w-full h-full flex flex-col items-center bg-white py-16 px-4 md:px-8 text-[#3B3B3B]"
      >
        <div className="w-full h-full max-w-[81rem] flex flex-col lg:flex-row gap-10 items-center">
          
          <motion.div
            className="flex-1"
            initial={{ opacity: 0, x: -50 }}
            animate={isInView2 ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <img
              src={img6}
              className="w-full max-h-[45rem] rounded-xl object-cover"
              alt=""
            />
          </motion.div>

        
          <motion.div
            className="flex-1 flex flex-col justify-between gap-y-5"
            initial={{ opacity: 0, x: 50 }}
            animate={isInView2 ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
          >
           
            <motion.div
              className="w-full flex flex-col gap-4"
              initial={{ opacity: 0, y: 20 }}
              animate={isInView2 ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.6, ease: "easeOut", delay: 0.4 }}
            >
              <span className="lg:text-3xl text-[1.8rem] font-semibold text-[#3B3B3B]">
                Your Health Data, Safeguarded with the Highest Standards
              </span>
              <span className="text-[#6B6B6B] h-[11rem] md:h-[8rem] leading-relaxed">
                At Labops, your privacy is our priority. We use advanced
                security to protect your health data, from test booking to
                report access. With top encryption, secure cloud storage, and
                global compliance, we ensure your medical information stays safe
                and private.
              </span>
            </motion.div>

          
            <motion.div
              className="w-full flex flex-col gap-y-6 lg:pl-5"
              initial={{ opacity: 0, y: 20 }}
              animate={isInView2 ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.7, ease: "easeOut", delay: 0.5 }}
            >
              {[
                {
                  title: "1. Data Encryption",
                  description:
                    "Your health data is fully encrypted during transmission and storage, preventing unauthorized access and ensuring secure data handling.",
                },
                {
                  title: "2. Secure Cloud-Based Storage",
                  description:
                    "We use highly secure cloud storage solutions to protect your medical records from cyber threats, giving you safe and easy access to your health information.",
                },
                {
                  title: "3. Multi-Factor Authentication (MFA)",
                  description:
                    "Labops strengthens account security with MFA, ensuring that only authorized users can access sensitive health information.",
                },
                {
                  title: "4. Compliance with Global Privacy Standards",
                  description:
                    "Labops meets healthcare regulations like HIPAA and GDPR, providing top-level compliance and security for your health data across all platforms.",
                },
              ].map((item, index) => (
                <motion.div
                  key={index}
                  className="w-full flex flex-col border-b-2 pb-5 border-b-[#F0F1F3]"
                  initial={{ opacity: 0, y: 20 }}
                  animate={isInView2 ? { opacity: 1, y: 0 } : {}}
                  transition={{
                    duration: 0.6,
                    ease: "easeOut",
                    delay: 0.6 + index * 0.2,
                  }}
                >
                  <span className="text-[1.2rem] font-medium">
                    {item.title}
                  </span>
                  <span className="text-[#6B6B6B]">{item.description}</span>
                </motion.div>
              ))}
            </motion.div>
          </motion.div>
        </div>
      </div> */}

      {/* <div className="bg-gradient-to-l py-[5rem] from-white to-blue-50">
        <div className=" mx-auto   max-w-[81rem] pb-14">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-3xl font-bold text-gray-900">
              Loved by our customers
            </h2>
            <div className="flex gap-3">
              <button
                onClick={() => sliderRef.current.slickPrev()}
                className="p-3 rounded-full bg-white border border-gray-200 hover:bg-gray-100 transition"
              >
                <ChevronLeft size={22} className="text-gray-600" />
              </button>
              <button
                onClick={() => sliderRef.current.slickNext()}
                className="p-3 rounded-full border border-gray-200 bg-white  hover:bg-gray-100 transition"
              >
                <ChevronRight size={22} className="text-gray-600" />
              </button>
            </div>
          </div>
          <Slider ref={sliderRef} {...settings}>
            {testimonials.map((t, index) => (
              <div key={index} className="px-3 py-4">
                <div className=" backdrop-blur-md p-6 bg-white rounded-2xl  shadow-lg  border border-gray-200">
                  <div className="flex items-center gap-4">
                    <img
                      src={t.image}
                      alt={t.name}
                      className="w-12 h-12 rounded-full border border-gray-300"
                    />
                    <div>
                      <p className="font-semibold text-lg text-gray-800">
                        {t.name}
                      </p>
                      <p className="text-sm text-gray-500">{t.position}</p>
                    </div>
                    <div className="ml-auto">
                      <span className=" px-4 py-1 text-sm rounded-full border border-gray-200">
                        {t.company}
                      </span>
                    </div>
                  </div>
                  <p className="mt-5 text-gray-700 leading-relaxed">
                    {t.message}
                  </p>
                  <div className="flex items-center justify-between mt-5 text-sm text-gray-400">
                    <img src={t.companyLogo} alt={t.company} className="w-14" />
                    <span>{t.date}</span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div> */}
      <div className="w-full flex justify-center bg-gradient-to-l from-white to-blue-50 px-4 md:px-0">
        <section className="relative w-full  py-[5rem]  md:py-0 md:h-[40rem]  flex flex-col-reverse lg:flex-row items-center justify-between mx-auto rounded-xl bg-gradient-to-l from-white to-blue-50 overflow-hidden ">
          {/* Text Section */}
          <div className="text-center h-full flex-col  flex items-center justify-center   mx-auto w-full max-w-[81rem] p-5 md:py-0 lg:text-left lg:pl-0">
            <h2 className="text-2xl leading-snug uppercase sm:text-3xl md:text-[42px] text-center font-bold text-[#338bff]">
              Finally a better way to make <br />
              <span className="md:mt-5 block uppercase">healthcare better</span>
            </h2>
            <p className="text-sm sm:text-base text-center text-blue-500 mt-8">
              Our main pillars to create the best user experience are seamless
              accessibility, intuitive design, personalized interactions.
            </p>
          </div>

          {/* Image Section */}
          {/* <div className="w-full right-0  absolute xl:max-w-[35rem]">
            <img
              src={doctorHalfImg}
              alt="Doctor holding tablet"
              className="w-[70rem] "
            />
          </div> */}
        </section>
      </div>
      {/* <InfiniteMarquee /> */}

      <div className="w-full h-full flex flex-col items-center  bg-gradient-to-l from-white to-blue-50  py-16 lg:py-16 px-4 md:px-8 lg:px-8 text-[#3B3B3B]">
        <div className="w-full flex  max-w-[81rem] flex-col  gap-y-5 gap-x-10 items-start">
          {/* Left Text Section */}
          <div className="lg:flex-1 flex justify-between w-full mb-[4rem]">
            <div className="w-full flex flex-col gap-y-3">
              <span className="text-[#3B3B3B] text-3xl md:text-4xl font-bold">
                Insights don’t miss to read
              </span>
              <span className="text-[#6B6B6B] text-lg">
                Explore a wide range of informative articles to stay updated on
                important health topics
              </span>
            </div>
            {blogs?.length > 3 && (
              <div
                onClick={() => navigate("Blogs")}
                className="w-[9rem] cursor-pointer transition-all border-[#488FED] ease-in-out duration-300 border-[1px] flex items-center justify-center rounded-[0.5rem] h-[2.5rem] md:h-[3.5rem] mt-5"
              >
                <button className="flex items-center gap-2 text-[#488FED] ">
                  See All <ArrowRight size={18} />
                </button>
              </div>
            )}
          </div>

          {/* Right Image Section */}
          <div className="w-full h-full flex flex-col items-center  bg-gradient-to-l from-white to-blue-50  px-0 md:px-8 lg:px-0 text-[#3B3B3B]">
            <div className="w-full max-w-[81rem] grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-8">
              {blogs?.slice(0, 3).map((blog, index) => {
                const isExpanded = expanded[index];
                const description = blog?.short_description;

                return (
                  <div
                    key={index}
                    className="bg-white h-auto p-6 rounded-lg border border-gray-200 flex flex-col gap-4"
                  >
                    <img
                      onClick={() => navigate(`/blog-detail/${blog?.slug}`)}
                      src={`${blog?.image}`}
                      alt="Article Image"
                      className="w-full h-[15rem] cursor-pointer rounded-lg object-cover"
                    />
                    {/* <img
                      onClick={() => navigate(`/blog-detail/${blog?.slug}`)}
                      src={`${process.env.REACT_APP_BASEURL}/${blog?.image}`}
                      alt="Article Image"
                      className="w-full h-[15rem] cursor-pointer rounded-lg object-cover"
                    /> */}

                    <div className="flex items-center gap-x-4 text-[#6B6B6B] text-sm">
                      <span>{formatDate(blog?.publish)}</span>
                      {/* <span className="block bg-[#6B6B6B] h-2 w-2 rounded-full"></span>
                      <span>12 mins read</span> */}
                    </div>
                    <h3 className="text-xl font-semibold text-[#3B3B3B]">
                      {blog?.title}
                    </h3>
                    <div className="relative">
                      <p
                        className={`text-[#3B3B3B] overflow-hidden ${
                          isExpanded ? "max-h-full" : "max-h-[4.5rem]"
                        }`}
                      >
                        {description}
                      </p>
                      {description?.length > 100 && (
                        <button
                          className="text-[#488FED] font-semibold mt-2"
                          onClick={() => navigate(`/blog-detail/${blog?.slug}`)}
                        >
                          {isExpanded ? "Read Less" : "Read More"}
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full flex flex-col items-center bg-gradient-to-l from-white to-blue-50 py-[1rem] md:py-[5rem] text-[#3B3B3B] px-4 md:px-0">
        <div className="w-full flex max-w-[81rem] flex-col lg:flex-row gap-y-5 gap-x-10 items-start">
          {/* Left Text Section */}
          <div className="lg:flex-1 w-full">
            <div className="w-full flex flex-col gap-y-3">
              <span className="text-[#3B3B3B] text-3xl md:text-4xl font-bold">
                Frequently Asked Questions
              </span>
              <span className="text-[#6B6B6B] text-lg">
                Get answers to the most commonly asked questions about Labops.
              </span>
            </div>

            {/* Accordion */}
            <div className="mt-8 flex flex-col gap-y-2">
              {accordionData.map((item, index) => (
                <div
                  key={index}
                  className={`border shadow-md border-[#E0E0E0]  rounded-xl py-4 transition-all duration-500 ease-in-out ${
                    activeIndex === index ? "bg-white  rounded-md" : "bg-white"
                  }`}
                >
                  <div
                    className="flex justify-between items-center px-5 py-2 cursor-pointer transition-transform transform"
                    onClick={() => toggleAccordion(index)}
                  >
                    <span className="text-[#3B3B3B] text-lg font-medium w-[90%]">
                      {item.question}
                    </span>
                    <span className="text-[#6B6B6B] bg-[#D9D9D9] flex justify-center items-center h-[1.6rem] w-[1.6rem] rounded-lg text-xl transition-transform duration-500 ease-in-out">
                      {activeIndex === index ? (
                        <ChevronUp size={20} />
                      ) : (
                        <ChevronDown size={20} />
                      )}
                    </span>
                  </div>

                  <div
                    className={`overflow-hidden px-5 transition-all duration-700 ease-in-out ${
                      activeIndex === index
                        ? "max-h-[1000px] opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                    style={{
                      transition:
                        "max-height 0.7s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.7s ease-in-out",
                    }}
                  >
                    <div className="mt-4 text-[#6B6B6B] text-base">
                      <p>{item.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right Image Section */}
          {/* <div className="md:flex-1 w-full lg:max-w-[35%]">
            <img
              src={img7}
              className="rounded-lg w-full object-cover shadow-md"
              alt="FAQ Image"
            />
          </div> */}
        </div>
      </div>

      <div className="flex justify-center items-center w-full px-4 md:px-0">
        <div className="flex max-w-[81rem]  w-full flex-col md:flex-row items-center justify-center mx-auto gap-5 py-[1rem] md:py-[5rem]  ">
          {/* Left Side - Image */}
          <div className="w-full md:w-1/2 flex justify-center">
            <img
              src={img6}
              alt="Happy User"
              className="w-full max-w-[500px] h-[350px] md:h-[400px] object-cover rounded-xl "
            />
          </div>

          {/* Right Side - Content */}
          <div className="w-full  h-[350px] md:h-[400px] flex flex-col justify-center bg-gradient-to-r from-[#3B82F6] to-[#6366F1] text-white p-8 md:p-10 rounded-xl ">
            <h2 className="text-2xl md:text-4xl text-center font-semibold leading-tight">
              Open an Labops account in <br />
              <span className="text-yellow-300">5 minutes</span>
            </h2>
            <p className="text-sm md:text-base text-gray-200 mt-4 text-center">
              Labs of all sizes can create an account and manage their entire
              lab operations seamlessly.
            </p>

            <a
              href="https://app.labops.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-6 text-black mx-auto  w-[12.5rem] flex items-center justify-center gap-2 bg-white text-sm md:text-base font-semibold h-[3.2rem] rounded-md  transition"
            >
              <ExternalLink />
              Open an Account
            </a>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center pb-[1rem] md:pb-[5rem] px-4 md:px-0">
        <div className="relative h-[35rem]  max-w-[81rem] w-full text-center px-4">
          {/* Background Image */}
          <img
            src={comingbg}
            alt="Background"
            className="absolute inset-0 h-[35rem] rounded-xl  right-0  object-cover z-[-1]"
          />

          {/* Content */}
          <div className="flex flex-col items-center justify-center h-full">
            <h1 className="md:text-6xl text-4xl  font-bold text-white">
              Coming Soon
            </h1>
            <p className="text-lg font-semibold text-white mt-4 max-w-lg">
              Experience the power of AI at your fingertips! 🚀 Launching soon
              on Google Play Store and APP Store. Stay tuned!
            </p>
            <div className="mt-6 flex gap-4">
              <img
                src={appplaystore} // Ensure this is correctly imported
                alt="Google Play Store"
                className="w-[30rem]  cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeComp;
