import React, { useState } from "react";
import img1 from "../Assets/About/img1.png";
import img2 from "../Assets/About/Img2.png";
import img3 from "../Assets/About/Img3.png";
import img4 from "../Assets/About/img4.png";
import img5 from "../Assets/About/Img5.png";
import img7 from "../Assets/Home/Rectangle 158.png";

import img9 from "../Assets/Home/009 1.png";
import { Check, Target, Info, PhoneCall, Linkedin } from "react-feather";

import {
  Activity,
  Award,
  ChevronDown,
  ChevronUp,
  Cpu,
  Lock,
} from "react-feather";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ShinyButton from "./magicui/ShinyButton";
import aniketImg from "../Assets/About/Aniket.jpeg";
import mridulImg from "../Assets/About/Mridul.jpeg";
import somojitImg from "../Assets/About/somojit.jpeg";
import debdityaImg from "../Assets/About/Debditya.jpeg";
import rishikeshImg from "../Assets/About/Rishikesh.jpeg";
import tm1 from "../Assets/About/tm_1.avif";
import tm2 from "../Assets/About/tm_2.avif";
import tm3 from "../Assets/About/tm_3.jpg";
import tm4 from "../Assets/About/tm_4.jpg";
function AboutComp() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const teamMembers = [
    {
      name: "Soumyajit Hui",
      position: "CEO",
      img: somojitImg, // Replace with actual image
      linkedInUrl: "https://www.linkedin.com/in/soumyajit-hui-813a52238/",
    },
    {
      name: "Debditya Mallick",
      position: "COO",
      img: debdityaImg, // Replace with actual image
      linkedInUrl: "https://www.linkedin.com/in/debditya-mallick-940779250/",
    },
    {
      name: "Aniket Verma",
      position: "Backend Engineer",
      img: aniketImg, // Assuming `img5` is already defined or imported
      linkedInUrl: "https://www.linkedin.com/in/aniketverma11/",
    },

    {
      name: "Mridul Singhal",
      position: "Backend Engineer",
      img: mridulImg, // Replace with actual image
      linkedInUrl:
        "https://www.linkedin.com/in/mridul-singhal-44aa62202/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
    {
      name: "Rishikesh Ghosh",
      position: "Frontend Engineer",
      img: rishikeshImg, // Replace with actual image
      linkedInUrl: "https://www.linkedin.com/in/rishikesh-ghosh-9a226a230/",
    },

    // Add more members as needed
  ];
  const mentorMembers = [
    {
      name: "Amir Khan",
      position: "MARL VC",
      img: tm1,
      linkedInUrl: "https://www.linkedin.com/in/amirkhan177/",
    },
    {
      name: "Prakash Goswami",
      position: "MARL VC",
      img: tm2,
      linkedInUrl: "https://www.linkedin.com/in/pgoswami/",
    },
    {
      name: "Ashish Mehra",
      position: "Tau Ventures",
      img: tm3,
      linkedInUrl: "https://www.linkedin.com/in/drashishmehra/",
    },

    {
      name: "Brendan Maxwell",
      position: "Workday",
      img: tm4,
      linkedInUrl: "https://www.linkedin.com/in/brendanmaxwell/",
    },
  ];
  const accordionData = [
    {
      question: "Is Labops suitable for both pathology and diagnostic labs?",
      answer:
        "Yes, Labops is designed to cater to the needs of both pathology and diagnostic labs, making it a versatile solution for various laboratory settings.",
    },
    {
      question: "How secure is Labops for health data?",
      answer:
        "Labops prioritizes your privacy and security. With end-to-end encryption, HIPAA compliance, and regular audits, your health data is safeguarded with the highest standards.",
    },
    {
      question: "Can I access Labops from mobile devices?",
      answer:
        "Yes, Labops is accessible from both desktop and mobile devices, ensuring you can manage your lab anytime, anywhere.",
    },
    {
      question: "Does Labops support multi-location labs?",
      answer:
        "Absolutely! Labops is equipped to handle multi-location labs efficiently, allowing you to manage all your branches from a single dashboard.",
    },
    {
      question: "Does Labops support multi-location labs?",
      answer:
        "Absolutely! Labops is equipped to handle multi-location labs efficiently, allowing you to manage all your branches from a single dashboard.",
    },
  ];
  return (
    <>
      <div className="w-full flex flex-col items-center bg-gradient-to-l from-white to-blue-50 py-12 px-4 pt-24 lg:pt-40">
        <div className="w-full max-w-[81rem]  lg:max-h-[50rem] flex flex-col lg:flex-row gap-4 lg:gap-8 items-center lg:items-start">
          {/* Text and Image Block 1 */}
          <div className="flex  flex-col flex-1  text-center lg:text-left">
            <div className="flex h-[15rem] flex-col  gap-6 mb-0 lg:mb-0">
              <span className="text-4xl text-start lg:text-5xl xl:text-6xl font-bold text-gray-800">
                About Us
              </span>
              <p className="text-gray-700 text-start text-base  leading-relaxed">
                Labops is transforming healthcare in India. We’re building an
                AI-powered platform that simplifies healthcare management,
                making it faster, smarter, and more accessible for patients and
                families.
              </p>
            </div>
            <div className="relative w-full  mx-auto lg:mx-0">
              <img
                src={img2}
                alt="Labops"
                className="w-full lg:h-[30rem] shadow-md  object-cover"
              />
            </div>
          </div>

          {/* Text and Image Block 2 */}
          <div className="lg:w-[35%] w-full text-center lg:text-left">
            <div className="relative w-full  mx-auto lg:mx-0">
              <img
                src={img1}
                alt="Healthcare Precision"
                className="w-full  h-[30rem] shadow-md  lg:h-[45rem]  object-cover"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center bg-gradient-to-l from-white to-blue-50 py-12 px-4 ">
        <div className="w-full  max-w-[81rem]  flex flex-col lg:flex-row gap-x-5">
          <div className="flex flex-1 flex-col w-full ">
            <div className="gap-y-4 pb-10 flex flex-col border-b-[#F3EFE7] border-b-[2px]">
              <span className="lg:text-4xl text-3xl text-[#3B3B3B] font-[500] ">
                Story behind Labops
              </span>
              <span className="text-[#333333]">
                Labops was born from a simple idea: What if healthcare could be
                as seamless as ordering food? Frustrated by long wait times,
                confusing processes, and fragmented systems, we created a
                platform that empowers patients to manage their health
                effortlessly.
              </span>
            </div>

            <div className="flex-1 flex items-start justify-center  py-10 gap-y-6 flex-col">
              <div className="flex items-center justify-center gap-x-3">
                <div className="w-[1.3rem] flex items-center justify-center h-[1.3rem] rounded-full bg-[#488FED]">
                  <Check size={14} color="white"></Check>
                </div>
                <span className="text-[#3B3B3B]">
                  Collaborative Achievements
                </span>
              </div>{" "}
              <div className="flex items-center justify-center gap-x-3">
                <div className="w-[1.3rem] flex items-center justify-center h-[1.3rem] rounded-full bg-[#488FED]">
                  <Check size={14} color="white"></Check>
                </div>
                <span className="text-[#3B3B3B]">Team Achievements</span>
              </div>{" "}
              <div className="flex items-center justify-center gap-x-3">
                <div className="w-[1.3rem] flex items-center justify-center h-[1.3rem] rounded-full bg-[#488FED]">
                  <Check size={14} color="white"></Check>
                </div>
                <span className="text-[#3B3B3B]">Client Success Stories</span>
              </div>{" "}
              <div className="flex items-center justify-center gap-x-3">
                <div className="w-[1.3rem] flex items-center justify-center h-[1.3rem] rounded-full bg-[#488FED]">
                  <Check size={14} color="white"></Check>
                </div>
                <span className="text-[#3B3B3B]">Future Aspirations</span>
              </div>{" "}
              <div className="flex items-center justify-center gap-x-3">
                <div className="w-[1.3rem] flex items-center justify-center h-[1.3rem] rounded-full bg-[#488FED]">
                  <Check size={14} color="white"></Check>
                </div>
                <span className="text-[#3B3B3B]">Community Engagement</span>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <img src={img2} className="w-full shadow-md" alt="" />
          </div>
        </div>
      </div>
      <div className=" py-12 px-4 bg-gradient-to-l from-white to-blue-50  flex flex-col items-center">
        <div className=" max-w-[81rem]  mx-auto flex flex-col-reverse lg:flex-row lg:space-x-4 ">
          {/* Left Column */}
          <div className="w-full lg:w-[40%] flex flex-col lg:gap-y-4">
            {/* Card 1 */}
            <div className="bg-white  p-6 lg:p-8 flex flex-col items-start  shadow-md">
              <div className="flex items-start space-x-4">
                <div className="flex flex-col gap-y-3">
                  <div className="w-10 h-10 flex justify-center items-center bg-[#488FED] text-white rounded-full">
                    <Target size={28} />
                  </div>
                  <h2 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                    Our Mission
                  </h2>
                  <p className="text-gray-600">
                    We aim to revolutionize healthcare by connecting labs,
                    patients, and technology. Our mission is to create a
                    seamless diagnostic experience for both labs and
                    patients—making healthcare more affordable and accessible
                    for everyone. By empowering labs with advanced tools and
                    offering patients AI-driven health insights, we’re
                    transforming healthcare management into a connected and
                    efficient process.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 2 */}
            <div className="bg-white  p-6 lg:p-8 flex flex-col items-start  shadow-md">
              <div className="flex items-start space-x-4">
                <div className="flex flex-col gap-y-3">
                  <div className="w-10 h-10 flex justify-center items-center bg-[#488FED] text-white rounded-full">
                    <Info size={28} />
                  </div>
                  <h2 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                    Our Vision
                  </h2>
                  <p className="text-gray-600">
                    We envision a future where healthcare is no longer a
                    challenge. Through AI and innovation, we aim to create a
                    world where every Indian can access affordable, transparent,
                    and hassle-free healthcare services – all in one place.
                  </p>
                </div>
              </div>
            </div>

            {/* Image */}
            <img
              src={img4}
              alt="Description"
              className="w-full flex-1 h-auto object-cover  shadow-md"
            />
          </div>

          {/* Right Column */}
          <div className="w-full lg:w-[60%] flex flex-col-reverse lg:flex-col  lg:gap-y-4">
            {/* Main Image */}
            <img
              src={img3}
              alt="Description"
              className="w-full h-[46rem] object-cover  shadow-md"
            />

            {/* Content Blocks */}
            <div className="flex flex-1 flex-col lg:flex-row lg:space-x-4  lg:gap-y-0">
              {/* Content Block 1 */}
              <div className="flex-1 bg-white  p-6 lg:p-8  shadow-md">
                <div className="flex items-start space-x-4 mb-0">
                  <div className="flex flex-col gap-y-3">
                    <div className="w-10 h-10 flex justify-center items-center bg-[#488FED] text-white rounded-full">
                      <Target size={28} />
                    </div>
                    <h2 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                      Our Approach
                    </h2>
                    <p className="text-gray-600">
                      We believe in solving healthcare challenges with
                      simplicity. By leveraging AI, we’ve designed a platform
                      that prioritizes speed, reliability, and seamless
                      healthcare management for everyone.
                    </p>
                  </div>
                </div>
              </div>

              {/* Content Block 2 */}
              <div className="flex-1 bg-white p-6 lg:p-8 shadow-md">
                <div className="flex items-start space-x-4">
                  <div className="flex flex-col gap-y-3">
                    <div className="w-10 h-10 flex justify-center items-center bg-[#488FED] text-white rounded-full">
                      <Info size={28} />
                    </div>
                    <h2 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-2">
                      Our Values
                    </h2>

                    <ul className="mt-3 space-y-2 text-gray-600">
                      <li className="flex items-center gap-2">
                        <span className="text-[#1a73e8]">●</span> Patient-first
                        care
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-[#1a73e8]">●</span> AI-driven
                        innovation
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-[#1a73e8]">●</span> Transparency &
                        trust
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="text-[#1a73e8]">●</span> Affordable
                        healthcare for all
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-l from-white to-blue-50 py-12 px-4 flex flex-col items-center">
        <div className="max-w-[81rem] w-full mx-auto flex flex-col gap-y-12">
          <div className="w-full flex flex-col items-center">
            <span className="text-[#6B6B6B]">Members</span>
            <span className="text-[#3B3B3B] text-3xl lg:text-4xl">
              Meet Our Team
            </span>
          </div>

          {/* Swiper for smaller screens */}
          <div className="md:hidden w-full flex justify-center">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              centeredSlides={true}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              {teamMembers.map((member, index) => (
                <SwiperSlide key={index} className="flex flex-col items-center">
                  <div className="flex justify-center pt-[5rem] ">
                    <img
                      src={member.img}
                      className="w-[13rem] h-[15rem] rounded-md mb-2 "
                      alt={member.name}
                    />
                  </div>

                  <div className="flex flex-col items-center">
                    <span className="text-[1.2rem] font-[500] text-[#3B3B3B]">
                      {member.name}
                    </span>
                    <span className="text-[#6B6B6B]">{member.position}</span>

                    {/* LinkedIn Icon */}
                    <a
                      href={member.linkedInUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Linkedin className="text-[#0072b1] mt-2" size={24} />
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* Grid layout for larger screens */}
          <div className="hidden md:grid lg:gap-10 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-10">
            {teamMembers.map((member, index) => (
              <div key={index} className="flex flex-col items-center">
                <img
                  src={member.img}
                  className="w-[20rem] h-[15rem] rounded-md mb-2"
                  alt={member.name}
                />
                <span className="text-[1.2rem] font-[500] text-[#3B3B3B]">
                  {member.name}
                </span>
                <span className="text-[#6B6B6B]">{member.position}</span>

                {/* LinkedIn Icon */}
                <a
                  href={member.linkedInUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Linkedin className="text-[#0072b1] mt-2" size={24} />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>{" "}
      <div className="bg-gradient-to-l from-white to-blue-50 py-12 px-4 flex flex-col items-center">
        <div className="max-w-[81rem] w-full mx-auto flex flex-col gap-y-12">
          <div className="w-full flex flex-col items-center">
            <span className="text-[#6B6B6B]">Members</span>
            <span className="text-[#3B3B3B] text-3xl lg:text-4xl">
              Meet Our Mentors
            </span>
          </div>

          {/* Swiper for smaller screens */}
          <div className="md:hidden w-full flex justify-center">
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              centeredSlides={true}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              {mentorMembers.map((member, index) => (
                <SwiperSlide key={index} className="flex flex-col items-center">
                  <div className="flex justify-center pt-[5rem] ">
                    <img
                      src={member.img}
                      className="w-[13rem] h-[15rem] rounded-md mb-2 "
                      alt={member.name}
                    />
                  </div>

                  <div className="flex flex-col items-center">
                    <span className="text-[1.2rem] font-[500] text-[#3B3B3B]">
                      {member.name}
                    </span>
                    <span className="text-[#6B6B6B]">{member.position}</span>

                    {/* LinkedIn Icon */}
                    <a
                      href={member.linkedInUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Linkedin className="text-[#0072b1] mt-2" size={24} />
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* Grid layout for larger screens */}
          <div className="hidden md:grid lg:gap-10 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-10">
            {mentorMembers.map((member, index) => (
              <div key={index} className="flex flex-col items-center">
                <img
                  src={member.img}
                  className="w-[20rem] h-[15rem] rounded-md mb-2"
                  alt={member.name}
                />
                <span className="text-[1.2rem] font-[500] text-[#3B3B3B]">
                  {member.name}
                </span>
                <span className="text-[#6B6B6B]">{member.position}</span>

                {/* LinkedIn Icon */}
                <a
                  href={member.linkedInUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Linkedin className="text-[#0072b1] mt-2" size={24} />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full h-full flex flex-col items-center bg-gradient-to-l from-white to-blue-50 py-16 lg:py-16 px-4 md:px-8 lg:px-8 text-[#3B3B3B]">
        <div className="w-full flex max-w-[81rem] flex-col lg:flex-row gap-y-5 gap-x-10 items-start">
          {/* Left Text Section */}
          <div className="lg:flex-1 w-full">
            <div className="w-full flex flex-col gap-y-3">
              <span className="text-[#3B3B3B] text-3xl md:text-4xl font-bold">
                Frequently Asked Questions
              </span>
              <span className="text-[#6B6B6B] text-lg">
                Get answers to the most commonly asked questions about Labops.
              </span>
            </div>

            {/* Accordion */}
            <div className="mt-8 flex flex-col gap-y-2">
              {accordionData.map((item, index) => (
                <div
                  key={index}
                  className={`border-b-2 border-[#E0E0E0] rounded-xl py-4 transition-all duration-500 ease-in-out ${
                    activeIndex === index
                      ? "bg-white shadow-lg rounded-md"
                      : "bg-white"
                  }`}
                >
                  <div
                    className="flex justify-between items-center px-5 py-2 cursor-pointer transition-transform transform"
                    onClick={() => toggleAccordion(index)}
                  >
                    <span className="text-[#3B3B3B] text-lg font-medium w-[90%]">
                      {item.question}
                    </span>
                    <span className="text-[#6B6B6B] bg-[#D9D9D9] flex justify-center items-center h-[1.6rem] w-[1.6rem] rounded-lg text-xl transition-transform duration-500 ease-in-out">
                      {activeIndex === index ? (
                        <ChevronUp size={20} />
                      ) : (
                        <ChevronDown size={20} />
                      )}
                    </span>
                  </div>

                  {/* Accordion content with smoother transition */}
                  <div
                    className={`overflow-hidden px-5 transition-all duration-700 ease-in-out ${
                      activeIndex === index
                        ? "max-h-[1000px] opacity-100"
                        : "max-h-0 opacity-0"
                    }`}
                    style={{
                      transition:
                        "max-height 0.7s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.7s ease-in-out",
                    }}
                  >
                    <div className="mt-4 text-[#6B6B6B] text-base">
                      <p>{item.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right Image Section */}
        </div>
      </div>
      <div className="w-full h-full relative pt-6 md:pt-0 flex flex-col items-center bg-white py-0 px-4 md:px-8 lg:px-6 text-[#3B3B3B]">
        <div className="w-full   max-w-[81rem]  flex flex-col gap-y-10 lg:flex-row items-center lg:gap-x-10">
          {/* Text Section */}
          <div className="flex-1 flex flex-col items-center lg:items-start gap-y-5 text-center lg:text-left">
            <h2 className="lg:text-6xl text-4xl leading-[3rem] font-bold lg:leading-[4.5rem]">
              We're Ready to Help, Contact Us Anytime!
            </h2>
            <p className="text-[#6B6B6B] text-base lg:text-lg">
              Got questions or need support? We're here to listen and assist you
              with whatever you need.
            </p>
            <div className="flex justify-start">
              <a href="/contact">
                <button className="bg-gradient-to-r from-[#4C8DF5] w-[16rem] mt-[1rem] to-[#1A73E8] text-[16px] font-semibold  max-w-[12rem] h-[3.5rem] rounded-lg flex items-center justify-center text-white shadow-md hover:shadow-lg transition-all duration-300 ease-in-out">
                  <span className="flex items-center">
                    <PhoneCall className="mr-2 w-5 h-5" />{" "}
                    {/* Phone icon with size */}
                    <span className="text-[16px] text-white">
                      Contact us now
                    </span>
                  </span>
                </button>
              </a>
            </div>
          </div>

          {/* Image Section */}
          <div className="flex-1 relative">
            <img src={img9} className="w-full object-cover" alt="Contact Us" />
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutComp;
