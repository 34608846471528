import axios from "axios";
import React, { useEffect, useState } from "react";
import logo from "../Assets/logo.png";
import { useNavigate } from "react-router-dom";
const BlogsComp = () => {
  const [blogs, setBlogs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [expanded, setExpanded] = useState({});

  const toggleReadMore = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const getAllBlogs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/blogs/`
      );
      console.log(response?.data);
      setBlogs(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Options for displaying date as "30 Jan 2024"
    const options = { day: "numeric", month: "short", year: "numeric" };

    // Format the date using toLocaleDateString with English locale
    return date.toLocaleDateString("en-GB", options);
  };

  useEffect(() => {
    getAllBlogs();
  }, []);
  return (
    <div className="w-full h-full flex flex-col pt-24 items-center bg-gradient-to-l from-white to-blue-50  py-16 lg:py-16 px-4 md:px-8 lg:px-8 text-[#3B3B3B]">
      <div className="w-full flex  max-w-[81rem] flex-col  gap-y-5 gap-x-10 items-start">
        {/* Left Text Section */}
        <div className="lg:flex-1 w-full">
          <div className="w-full flex flex-col gap-y-3">
            <span className="text-[#3B3B3B] text-3xl md:text-4xl font-bold">
              Explore blog's insights
            </span>
            <span className="text-[#6B6B6B] text-lg">
              Explore a wide range of informative articles to stay updated on
              important health topics
            </span>
          </div>
        </div>
        {/* Right Image Section */}
        <div className="w-full h-full flex flex-col items-center bg-gradient-to-l from-white to-blue-50  py-16 px-0 md:px-8 lg:px-0 text-[#3B3B3B]">
          <div className="w-full max-w-[81rem] grid grid-cols-1 gap-5 lg:grid-cols-3 lg:gap-8">
            {blogs?.map((blog, index) => {
              const isExpanded = expanded[index];
              const description = blog?.short_description;

              return (
                <div
                  key={index}
                  className="bg-white h-auto p-6 rounded-lg border border-gray-200 flex flex-col gap-4"
                >
                  {/* <img
                    onClick={() => navigate(`/blog-detail/${blog?.slug}`)}
                    src={`${process.env.REACT_APP_BASEURL}/${blog?.image}`}
                    alt="Article Image"
                    className="w-full cursor-pointer h-[15rem] rounded-lg object-cover"
                  /> */}

                  <img
                    onClick={() => navigate(`/blog-detail/${blog?.slug}`)}
                    src={`${blog?.image}`}
                    alt="Article Image"
                    className="w-full cursor-pointer h-[15rem] rounded-lg object-cover"
                  />

                  <div className="flex items-center gap-x-4 text-[#6B6B6B] text-sm">
                    <span>{formatDate(blog?.publish)}</span>
                    {/* <span className="block bg-[#6B6B6B] h-2 w-2 rounded-full"></span>
                      <span>12 mins read</span> */}
                  </div>
                  <h3 className="text-xl font-semibold text-[#3B3B3B]">
                    {blog?.title}
                  </h3>
                  <div className="relative">
                    <p
                      className={`text-[#3B3B3B] overflow-hidden ${
                        isExpanded ? "max-h-full" : "max-h-[4.5rem]"
                      }`}
                    >
                      {description}
                    </p>
                    {description.length > 100 && (
                      <button
                        className="text-[#488FED] font-semibold mt-2"
                        onClick={() => toggleReadMore(index)}
                      >
                        {isExpanded ? "Read Less" : "Read More"}
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsComp;
